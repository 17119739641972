<template>
  <div>
    <!-- 顶部导航栏 -->
      <div class="header_wrap">
          <div class="header_wrap_top">
            <div class="top_box type_area" :style="{left: -scrollX+'px'}">
              <div class="top_left">
                <span class="left">您好，欢迎光临安徽华人健康股份有限公司</span>
                <span class="name" v-if="role == 2">{{distributorInfo.contacter}}</span>
                <span class="name" v-else>{{buyerInfo.companyName}}</span>
                <span class="layout" @click="layoutClick">退出登录</span>
              </div>
              <div class="top_right">
                <div v-if="role == 2||role == 3">
                  <div class="right_item" @click="pageJump('/customer/myCustomer',1)">我的客户</div>
                  <div class="right_item" @click="goToPJExternalPage('/user/index')">管理中心</div>
                </div>
                <div v-if="role == 1||role == 3">
                  <div class="right_item" @click="pageJump('/personal/myOrder')">我的订单</div>
                  <div class="right_item" @click="pageJump('/personal/myOrder')">个人中心</div>
                </div>
                <div class="right_item" @click="goToPJExternalPage('/aboutus/newslist')">公告消息</div>
                <div class="right_item last" @click="goToExternalPage('https://admin.qidian.qq.com/static_proxy/b2b-qq/wpa-link/index.html#/person?uin=3491583644')">客服服务</div>
                <!-- <div class="hotline">全国服务热线<span>0551-63677603</span></div> -->
              </div>
            </div>
          </div>
        <div class="header_box type_area" :style="{left: -scrollX+'px'}">
          <div class="header_box_center">
            <div class="logo">
              <img src="@/assets/images/index/logo.png" alt="">
            </div>
            <div class="search">
              <el-autocomplete :fetch-suggestions="querySearchAsync" clearable @clear="keywordClick(searchValue,1)" @input="searchInput" @select="handleSearchSelect" :trigger-on-focus="false" placeholder="请输入商品名/通用名、商品编号、厂家" v-model="searchValue" @keyup.native.enter="keywordClick(searchValue,1)" class="input-with-select">
                <!-- <el-select v-model="searchSelect" @change="selectChange" slot="prepend" style="width: 66px;" placeholder="请选择">
                  <el-option label="综合" :value="1"></el-option>
                  <el-option label="编码" :value="2"></el-option>
                </el-select> -->
                <div slot="prepend">智能搜索</div>
                <div slot="append" style="cursor: pointer;padding: 0 16px;" @click="keywordClick(searchValue,1)"><img src="@/assets/images/index/search.svg" alt=""></div>
              </el-autocomplete>
              <!-- 搜索热词 -->
              <div class="hot_search">
                <span v-for="(item,index) in KeywordsList" :key="index" @click="keywordClick(item.keyword,2)">{{item.keyword}}</span>
              </div>
            </div>
            <div class="shopcart" @click="pageJump('/shopCar',1)">
              <div class="badge_box">
                <div class="badge" v-if="carNumber>0">{{carNumber>99?'99+':carNumber}}</div>
                <img class="img" src="@/assets/images/index/cgc.svg" alt="">
              </div>
              <span class="lineheight">采购车</span>
            </div>
          </div>
          <div class="header_box_bottom">
            <div class="cate_box">
              <img class="img" src="@/assets/images/index/menu.svg" alt="">
              <span style="line-height: 40px;">所有商品分类</span>
              <div class="cate_content_box" v-if="isCateBoxShow">
                <div class="content_left">
                  <div class="left_item" :class="{active:cateIndex == item.id}" @mouseenter="cateClick(item.id)" v-for="(item,index) in cateList" :key="item.id">
                    <div class="active_sty" v-if="cateIndex == item.id"></div>
                    <div>
                      <img v-if="cateIndex == item.id" :src="getImg(index,'active')" alt="">
                      <img v-else :src="getImg(index)" alt="">
                      <span>{{item.categoryName}}</span>
                    </div>
                    <img v-if="cateIndex == item.id" src="@/assets/images/index/cate_jta.svg" alt="">
                    <img v-else src="@/assets/images/index/cate_jt.svg" alt="">
                  </div>
                </div>
                <div class="content_right">
                  <span @click="pageJumpCate('/onlineOrder?categoryId=' + item.id +'&categoryName=' + item.categoryName)" v-for="item in cateChilden" :key="item.id">{{item.categoryName}}</span>
                </div>
              </div>
            </div>
            <div class="menu_box">
              <el-menu :default-active="activeIndex" :router="false" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="/index" @click="pageJump('/index')">首页</el-menu-item>
                <el-menu-item index="/onlineOrder" @click="pageJump('/onlineOrder')">在线下单</el-menu-item>
                <el-submenu index="3">
                  <template slot="title">促销活动</template>
                  <el-menu-item index="3-1" @click="goToPJExternalPage('/special/index')">今日特价</el-menu-item>
                  <el-menu-item index="3-2" @click="goToPJExternalPage('/promotion/index')">促销专区</el-menu-item>
                  <el-menu-item index="3-3" @click="goToPJExternalPage('/activity/index')">活动专区</el-menu-item>
                  <el-menu-item index="3-4" @click="goToPJExternalPage('/gifts/index')">采满有赠</el-menu-item>
                  <el-menu-item index="3-5" @click="goToPJExternalPage('/presell/presell-index')">预售专区</el-menu-item>
                </el-submenu>
                <el-menu-item index="4" @click="goToPJExternalPage('/ctrlmarketing/index')">全方专区</el-menu-item>
                <el-menu-item index="5" @click="goToPJExternalPage('/product/brand')">品牌专区</el-menu-item>
                <el-menu-item index="6" @click="goToExternalPage('https://wx.vzan.com/live/pc/index?liveId=573790&shareuid=0',1)">华人健康商学院</el-menu-item>
              </el-menu>
            </div>
          </div>
        </div>
      </div>
    <!-- 悬浮工具栏 -->
    <div class="toolbar_wrap" id="myElement">
      <div class="type_area">
        <!-- 返回订单 -->
        <div class="back_order" v-if="pageType == 'audit'&&(currentUrl == '/onlineOrder'||currentUrl == '/goodsDetail')" @click="pageJump('/orderInfo?type=audit&prepareOrderNumber='+$route.query.prepareOrderNumber,2)">
          <span class="badge" v-if="orderNumber>0">{{orderNumber>99?'99+':orderNumber}}</span>
          <span class="txt">返回订单</span>
          <i class="el-icon-arrow-right icon"></i>
        </div>
        <div class="toolbar_box">
          <div class="xz" @click="goToPJExternalPage('/download/link',1)">
            <div class="xf_box jc">
              <span class="jt"></span>
              <div>下载到桌面</div>
            </div>
          </div>
          <div class="wddd" @click="pageJump('/personal/myOrder')">
            <div class="xf_box jc">
              <span class="jt"></span>
              <div>我的订单</div>
            </div>
          </div>
          <div class="yhq" @click="pageJump('/personal/myCoupon')">
            <div class="xf_box jc">
              <span class="jt"></span>
              <div>我的优惠券</div>
            </div>
          </div>
          <div class="wdsc" @click="pageJump('/personal/myFavorites')">
            <div class="xf_box jc">
              <span class="jt"></span>
              <div>我的收藏</div>
            </div>
          </div>
          <div class="cgls" @click="pageJump('/personal/purchaseHistory')">
            <div class="xf_box jc">
              <span class="jt"></span>
              <div>采购历史</div>
            </div>
          </div>
          <div class="kf">
            <div class="xf_box kf_box">
              <span class="jt"></span>
              <div @click="goToExternalPage('https://admin.qidian.qq.com/static_proxy/b2b-qq/wpa-link/index.html#/person?uin=3491583644')">在线客服</div>
              <div @click="goToExternalPage('https://admin.qidian.qq.com/static_proxy/b2b-qq/wpa-link/index.html#/person?uin=3491583644')">客服小洁</div>
            </div>
          </div>
          <div class="cgc" @click="pageJump('/shopCar',1)">
            <div class="xf_box jc">
              <span class="jt"></span>
              <div>采购车</div>
            </div>
          </div>
          <div class="ewm">
            <div class="xf_box">
              <span class="jt"></span>
              <img src="@/assets/images/index/khd_ewm.png" alt="">
              <div>扫一扫下载客户端</div>
              <div>随时随地轻松采购</div>
            </div>
          </div>
          <div class="zd" id="backToTopButton" @click="backtop"></div>
        </div>
      </div>
    </div>
      <!-- 顶部导航栏END -->
  </div>
</template>
<script>
import { getCarNum,getOrderNum } from '@/utils/carNumber'
export default {
  name: 'Head',
  data() {
    return {
      scrollX: 0,
      role: null,//角色
      isCateBoxShow: true,
      currentUrl: '',
      pageType: '',
      searchSelect: 1,
      searchValue: '',
      activeIndex: '/index',
      cateList: [],//一级分类列表
      cateIndex: null,
      cateChilden: [],//二级分类列表
      buyerInfo: {},//客户基本信息
      distributorInfo: {},//业务员基本信息
      KeywordsList: [],//热搜词列表
    };
  },
  watch: {
    // 监听路由对象的变化
    '$route'(to, from) {
      // 判断代客下单跳转过来
      if(to.path == '/empty'){
        this.resetClick()
      }
      // 获取订单数量
      if(this.$route.query.prepareOrderNumber) getOrderNum(this)
      // 当路由发生变化时，你可以在这里处理你的逻辑
      this.currentUrl = to.path
      this.toolShowScroll()
      this.pageType = to.query.type
      this.activeIndex = this.currentUrl
      if(this.currentUrl != '/onlineOrder'&&this.currentUrl != '/goodsDetail'){
        this.searchValue = ''
        // this.searchSelect = 1
      }
      // 你可以在这里根据路由做出相应的处理
    }
  },
  computed: {
    getOrderNumber(){
      return this.$store.getters.getOrderNumber;
    },
    getCarNumber(){
      return this.$store.getters.getCarNumber;
    },
    orderNumber() {
      return this.getOrderNumber;
    },
    carNumber() {
      return this.getCarNumber;
    }
  },
  created() {
    // 组件创建时获取初始路由
    this.handleRoute(this.$route);
    this.activeIndex = this.$route.path
    // 监听页面滚动展示侧边工具栏
    this.toolShow()
    // 获取分类列表
    this.getCateList()
    // 获取客户基本信息
    this.getBaseInfo()
    // 获取热搜词列表
    this.getKeywordsList()
    // 获取购物车数量
    getCarNum(this)
    // 获取订单数量
    if(this.$route.query.prepareOrderNumber) getOrderNum(this)
  },
  mounted(){
    this.toolShowScroll()
    // 判断路由是否存在参数
    this.searchValue = this.$route.query.keyword?this.$route.query.keyword:this.searchValue
    // this.searchSelect = this.$route.query.searchSelect?Number(this.$route.query.searchSelect):1
    // 监听横向滚动条
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.toolShowScroll);
  },
  methods: {
    // 重置数据
    resetClick(){
      // 组件创建时获取初始路由
      this.handleRoute(this.$route);
      this.activeIndex = this.$route.path
      // 监听页面滚动展示侧边工具栏
      window.removeEventListener('scroll', this.toolShowScroll);
      this.toolShow()
      this.toolShowScroll()
      // 获取分类列表
      this.getCateList()
      // 获取客户基本信息
      this.getBaseInfo(1)
      // 获取热搜词列表
      this.getKeywordsList()
      // 获取购物车数量
      getCarNum(this)
      // 获取订单数量
      if(this.$route.query.prepareOrderNumber) getOrderNum(this)
      // 判断路由是否存在参数
      this.searchValue = this.$route.query.keyword?this.$route.query.keyword:this.searchValue
      // this.searchSelect = this.$route.query.searchSelect?Number(this.$route.query.searchSelect):1
      // 监听横向滚动条
      window.addEventListener('scroll', this.handleScroll);
    },
    handleScroll() {
      this.scrollX = window.pageXOffset || document.documentElement.scrollLeft;
    },
    // 搜索框输入
    searchInput(val){
      console.log(val)
      if(!val) this.keywordClick(this.searchValue)
    },
    // 分类页面跳转
    pageJumpCate(url){
      this.isCateBoxShow = false
      setTimeout(() => {
        this.isCateBoxShow = true
      }, 200);
      this.searchValue = ''
      // this.searchSelect = 1
      this.$router.push(url)
    },
     // 页面跳转
    pageJump(url,name){
      // this.currentName = name
      // this.currentUrl = url
      this.$router.push(url)
    },
    // 头部右上角页面跳转
    pageJump(url,num){
      if(num == 1){
        this.activeIndex = ''
      }
      if(num == 2){
        // console.log(this.$router.history);
        this.$router.replace(url)
      }else{
        this.$router.push(url)
      }
    },
    // 链接跳转
    goToExternalPage(url,num) {
      this.activeIndex = ''
      setTimeout(() => {
        this.activeIndex = this.$route.path
      }, 100);
      // console.log(url)
      // 将URL设置为你想要跳转的外部页面
      if(num == 1){
        window.open(url)
      }else{
        window.location.href = url;
      }
    },
    // 拼接链接跳转
    goToPJExternalPage(url,num) {
      this.activeIndex = ''
      setTimeout(() => {
        this.activeIndex = this.$route.path
      }, 100);
      // console.log(url)
      // 页面跳转增加loading
        const loading = this.$loading();
      // 将URL设置为你想要跳转的外部页面
      window.location.href = process.env.VUE_APP_TZURL + url;
      setTimeout(() => {
        loading.close()
      }, 2300);
      // if(num == 1){
      //   window.location.href = process.env.VUE_APP_TZURL + url;
      // }else{
      //   window.open(process.env.VUE_APP_TZURL + url)
      // }
    },
    handleRoute(route) {
      // 处理路由的方法
      this.currentUrl = route.path
      this.pageType = route.query.type
    },
    // 获取客户基本信息
    getBaseInfo(num){
      this.$api.buyerBaseInfo().then((res) => {
        if (res.data.code === 200) {
          this.buyerInfo = res.data.data.buyerInfo
          this.$store.commit('setMember', res.data.data.buyerInfo)
          this.distributorInfo = res.data.data.distributorInfo
          this.role = res.data.data.role
          localStorage.setItem('roleNumber', this.role)
          if(this.role == 2){
            let first = localStorage.getItem('roleFrist')
            if(first == 1){
              this.$router.push('/customer/customerIndex')
              localStorage.setItem('roleFrist', 2)
            }
          }
          if(num == 1){
            if(this.buyerInfo.memberAttr == 1){
              this.$router.push('/index')
            }else if(this.buyerInfo.memberAttr == 2){
              this.$router.push('/onlineOrder')
            }else if(this.$route.query.roleNumber == 3){
              this.$router.push('/customer/myCustomer')
            }
          }
        }
      });
    },
    // 获取热搜词列表
    getKeywordsList(){
      this.$api.pcKeywords().then((res) => {
        if (res.data.code === 200) {
          this.KeywordsList = res.data.data
        }
      });
    },
    // 获取分类列表
    getCateList(){
      this.$api.pcProductCategory().then((res) => {
        if (res.data.code === 200) {
          this.cateList = res.data.data.categoryResp
          this.cateIndex = this.cateList[0].id
          this.cateChilden = this.cateList[0].childen
        }
      });
    },
    // 选择一级分类
    cateClick(id){
      this.cateIndex = id
      this.cateList.forEach(item=>{
        if(item.id == id){
          this.cateChilden = item.childen
        }
      })
    },
    // 通过 index 动态获取图片名称，用来展示
    getImg(index,str) {
      if(str == 'active'){
        return require("@/assets/images/index/cateicon" + index + "_a.svg");
      }else{
        return require("@/assets/images/index/cateicon" + index + ".svg");
      }
    },
    // 回到顶部
    backtop(){
      // document.getElementById('backToTopButton').addEventListener('click', function() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth' // 平滑滚动效果
        });
      // });
    },
    toolShowScroll(){
      // 获取需要显示的元素
      var element = document.getElementById('myElement');
      element.style.display = 'block';
      // 获取元素距离文档顶部的距离
      var elementTop = 270;
      // 获取滚动条当前位置
      var scrollPosition = window.scrollY;
      // 当滚动条位置大于等于元素距离顶部的距离时显示元素
      if(this.currentUrl == '/index'){
        if (scrollPosition < elementTop) {
          element.style.display = 'none'; // 或其他显示方式
        }else{
          element.style.display = 'block';
        }
      }
    },
    // 侧边工具栏显示
    toolShow(){
      // 监听滚动事件
      window.addEventListener('scroll', this.toolShowScroll)
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleSearchSelect(item){
      this.keywordClick(item.value,1)
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    // 编码综合切换
    selectChange(){
      if(this.currentUrl == '/onlineOrder'&&this.searchValue){
        this.keywordClick(this.searchValue,1)
      }
    },
    // 搜索提示
    querySearchAsync(queryString, cb){
      let results = []
      if(queryString === ''){
        cb(results)
      }else{
        let params = {
          search: this.searchValue,
          // search: this.searchSelect == 1?this.searchValue:'',
          // product_code: this.searchSelect == 2?this.searchValue:''
        }
        this.$api.pcGoodsTips(params).then((res) => {
          if (res.data.code === 200) {
            results = res.data.data.name.map(value=>({value}))
            cb(results)
          }
        });
      }
    },
    // 热搜词搜索
    keywordClick(keyword,num){
      // if(!keyword) return
      this.searchValue = keyword
      // if(num == 2){
      //   this.searchSelect = 1
      // }
      if(this.$route.query.prepareOrderNumber&&this.$route.path=='/onlineOrder'){
        // this.pageJump('/onlineOrder?keyword='+keyword+'&searchSelect='+this.searchSelect+'&type=audit&prepareOrderNumber='+this.$route.query.prepareOrderNumber)
        this.pageJump('/onlineOrder?keyword='+keyword+'&type=audit&prepareOrderNumber='+this.$route.query.prepareOrderNumber)
      }else{
        // this.pageJump('/onlineOrder?keyword='+keyword+'&searchSelect='+this.searchSelect)
        this.pageJump('/onlineOrder?keyword='+keyword)
      }
    },
    // 退出登录
    layoutClick(){
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.$api.visitorLogout().then((res) => {
          if (res.data.code === 200) {
            localStorage.removeItem('roleFrist')
            localStorage.removeItem('roleNumber')
            this.$router.push('/login')
          }
        });
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消退出'
          });          
        });
    },
  }
};
</script>
<style lang="scss" scoped>
.toolbar_wrap{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  .type_area{
    position: relative;
    height: 100%;

    .back_order{
      position: absolute;
      right: -21px;
      top: 190px;
      width: 56px;
      height: 125px;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      background: linear-gradient( 177deg, #1A9DFF 0%, #0687FE 100%);
      box-shadow: 0px 3px 6px 1px rgba(114,114,114,0.16);
      border-radius: 28px 28px 28px 28px;
      color: #FFFFFF;
      pointer-events: auto;
      cursor: pointer;

      .badge{
        align-self: center;
        padding: 1px 9px;
        font-size: 12px;
        background: #FF4949;
        border-radius: 9px 9px 9px 9px;
        border: 1px solid #FFB4B4;
      }
      .txt{
        align-self: center;
        font-size: 14px;
        margin-top: 9px;
        writing-mode: vertical-rl;
      }
      .icon{
        position: absolute;
        top: 65px;
        right: 3px;
      }
    }

    .toolbar_box{
      position: absolute;
      right: -21px;
      top: 320px;
      width: 56px;
      background: #FFFFFF;
      border-radius: 29px;
      box-shadow: 0px 3px 6px 1px rgba(114,114,114,0.16);

      &>div{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 50px;
        border-radius: 25px 25px 25px 25px;
        pointer-events: auto;

        &:hover{
          .xf_box{
            opacity: 1;
            visibility: visible;
          }
        }

        &::before{
          content: "";
          display: inline-block;
          width: 24px;
          height: 24px;
        }

        &:last-of-type{
          border-radius: 0px 0px 29px 29px;
        }
        &:first-of-type{
          border-radius: 29px 29px 0px 0px;
        }

        &:hover{
          cursor: pointer;
          background: linear-gradient( 170deg, #1A9DFF 0%, #0687FE 100%);

          &::after{
            height: 0;
          }

          &.xz::before{
            background-image: url("~@/assets/images/index/tool_xz_a.svg");
          }
          &.wddd::before{
            background-image: url("~@/assets/images/index/tool_dd_a.svg");
          }
          &.yhq::before{
            background-image: url("~@/assets/images/index/tool_yhq_a.svg");
          }
          &.wdsc::before{
            background-image: url("~@/assets/images/index/tool_wdsc_a.svg");
          }
          &.cgls::before{
            background-image: url("~@/assets/images/index/tool_cgls_a.svg");
          }
          &.kf::before{
            background-image: url("~@/assets/images/index/tool_kf_a.svg");
          }
          &.cgc::before{
            background-image: url("~@/assets/images/index/tool_cgc_a.svg");
          }
          &.ewm::before{
            background-image: url("~@/assets/images/index/tool_ewm_a.svg");
          }
          &.zd::before{
            background-image: url("~@/assets/images/index/tool_zd_a.svg");
          }
        }

        &::after{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 41px;
          height: 1px;
          background: linear-gradient( 90deg, rgba(238,238,238,0.06) 0%, #EEEEEE 46%, rgba(238,238,238,0.06) 100%);
          border-radius: 0px 0px 0px 0px;
        }
      }
      .xz{
        &::before{
          background-image: url("~@/assets/images/index/tool_xz.svg");
        }
      }
      .wddd{
        &::before{
          background-image: url("~@/assets/images/index/tool_dd.svg");
        }
      }
      .yhq{
        &::before{
          background-image: url("~@/assets/images/index/tool_yhq.svg");
        }
      }
      .wdsc{
        &::before{
          background-image: url("~@/assets/images/index/tool_wdsc.svg");
        }
      }
      .cgls{
        &::before{
          background-image: url("~@/assets/images/index/tool_cgls.svg");
        }
      }
      .kf{
        position: relative;
        &::before{
          background-image: url("~@/assets/images/index/tool_kf.svg");
        }
        &:hover{
          .xf_box{
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .cgc{
        &::before{
          background-image: url("~@/assets/images/index/tool_cgc.svg");
        }
      }
      .ewm{
        position: relative;
        &::before{
          background-image: url("~@/assets/images/index/tool_ewm.svg");
        }
        &:hover{
          .xf_box{
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .zd{
        &::before{
          background-image: url("~@/assets/images/index/tool_zd.svg");
        }
      }
      .xf_box{
        position: absolute;
        left: 66px;
        bottom: -20px;
        padding: 14px 16px;
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        color: #989898;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;

        &.jc{
          width: max-content;
          bottom: 4px;
          padding: 10px;
          font-size: 14px;
          color: #333333;

          .jt{
            bottom: 12px;
          }
        }

        .jt{
          position: absolute;
          bottom: 34px;
          left: -15px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid transparent;
          border-right: 8px solid #fff;
        }
        
        img{
          width: 96px;
          height: 96px;
        }

        &.kf_box{
          width: 60px;
          padding: 8px 12px 4px;
          font-size: 14px;
          color: #333333;

          &>div{
            padding: 8px 0;
            border-bottom: 1px dashed #EEEEEE;

            &:last-of-type{
              border: none;
            }
            &:hover{
              color: #1A9DFF;
            }
          }
        }
      }
    }
  }
}
.header_wrap{
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0px -3px 10px 6px rgba(202, 202, 202, 0.16);

  .header_wrap_top{
      background: #F0F0F0;

      .top_box{
        position: relative;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #999999;

        .top_left{
          display: flex;
          align-items: center;

          .left{
            margin-right: 12px;
          }

          .name{
            color: #F34141;
            &::after{
              content: "";
              display: inline-block;
              width: 1px;
              height: 8px;
              background: #B5B5B5;
              margin: 0 8px;
            }
          }

          .layout{
            &:hover{
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
        .top_right{
          position: relative;
          display: flex;
          align-items: center;

          .hotline{
            // position: absolute;
            right: -198px;

            span{
              font-size: 15px;
              color: #D63103;
              margin-left: 9px;
              font-weight: 600;
            }
          }

          &>div{
            display: flex;
            align-items: center;
          }

          .right_item{
            &:hover{
              opacity: 0.8;
              cursor: pointer;
            }
            &::after{
              content: "";
              display: inline-block;
              width: 1px;
              height: 8px;
              background: #B5B5B5;
              margin: 0 8px;
            }

            &.last{
              &::after{
                display: none;
              }
            }
          }
        }
      }
    }
  .header_box{
    position: relative;

    .header_box_center{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo{
        padding: 16px 0 18px;
        margin-left: 44px;

        img{
          width: 276px;
        }
      }

      .search{
        position: relative;
        margin: 0 36px 0 30px;
        flex: 1;

        ::v-deep{
          .el-input-group__prepend{
            border-right: 1px solid #DCDCDC;
            color: #333;
          }
          .el-input-group__append, .el-input-group__prepend{
            background: #fff;
            border-color: #DCDCDC;
          }
          .el-autocomplete{
            width: 100%;
          }
          .el-input__inner{
            border-left: none;
            border-color: #DCDCDC;
            color: #333;
          }
          .el-icon-search{
            font-size: 20px;
            color: #333333;
          }
          .el-input-group__prepend{
            padding: 0 16px;
          }
          .el-input-group__append{
            padding: 0;
          }
          .el-input--suffix .el-input__inner{
            padding-right: 8px;
          }
          .el-input__icon{
            width: 18px;
          }
          .el-icon-arrow-up:before{
            content: "";
            background-image: url("~@/assets/images/index/xiala.svg");
            width: 9px;
            height: 9px;
            display: inline-block;
            transform: rotate(180deg);
          }
          .el-select{
            position: relative;
            &:after{
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
              width: 1px;
              height: 12px;
              background: #DCDCDC;
            }
          }
        }

        .hot_search{
          position: absolute;
          bottom: -26px;
          left: 0;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #BCBCBC;

          span{
            position: relative;
            padding: 0 10px;
            &::after{
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
              width: 1px;
              height: 12px;
              background: #DCDCDC;
            }
            &:last-of-type{
              &::after{
                width: 0px;
              }
            }
            &:hover{
              color: #1A9DFF;
              cursor: pointer;
              transition: 0.3s;
            }
          }

        }
      }
      .shopcart{
        width: 107px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E9F6FF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #5EBAFF;
        font-size: 16px;
        color: #1A9DFF;
        cursor: pointer;

        .badge_box{
          position: relative;

          .badge{
            position: absolute;
            top: -10px;
            left: 10px;
            padding: 1px 4px;
            background: #FC2222;
            border-radius: 7px 7px 7px 7px;
            font-size: 9px;
            color: #FFFFFF;
          }
        }

        .img{
          margin-right: 8px;
        }
      }
    }
    .header_box_bottom{
      display: flex;
      align-items: center;

      .cate_box{
        position: relative;
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("~@/assets/images/index/cate_bj.png");
        border-radius: 4px 4px 0px 0px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;

        .img{
          margin-right: 8px;
          margin-top: 2px;
        }

        &:hover{
          .cate_content_box{
            // opacity: 1;
            // visibility: visible;
            display: flex;
          }
        }

        .cate_content_box{
          position: absolute;
          top: 40px;
          left: 0;
          background: #fff;
          box-shadow: 0px 9px 18px 2px rgba(202, 202, 202, 0.16);
          display: flex;
          color: #333;
          cursor: auto;
          // opacity: 0;
          // visibility: hidden;
          display: none;
          transition: 0.5s;

          .content_left{
            width: 220px;
            display: flex;
            flex-direction: column;
            .left_item{
              position: relative;
              z-index: 1;
              height: 66px;
              padding: 0 12px 0 18px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: bold;
              font-size: 16px;
              color: #333333;
              cursor: pointer;
              border-bottom: 1px dashed #EEEEEE;

              &.active{
                color: #fff;

                &:hover{
                  color: #fff;
                }
              }
              &:hover{
                color: #1A9DFF;
              }

              .active_sty{
                position: absolute;
                z-index: -1;
                right: 0;
                top: 0;
                width: 227px;
                height: 66px;
                background: linear-gradient( 95deg, #1A9DFF 0%, #0687FE 100%);
                
                &::before{
                  content: "";
                  position: absolute;
                  top: -6px;
                  left: 0;
                  border-width: 0 0 7px 7px;
                  border-style: solid;
                  border-color: transparent transparent #0071D9 transparent;
                }

                &::after{
                  content: "";
                  position: absolute;
                  bottom: -6px;
                  left: 0;
                  border-width: 0 7px 7px 0;
                  border-style: solid;
                  border-color: transparent #0071D9 transparent transparent;
                }
              }

              &:last-of-type{
                border: none;
              }

              &>div{
                display: flex;
                align-items: center;

                img{
                  margin-right: 10px;
                }
              }
            }
          }
          .content_right{
            align-self: self-start;
            width: 872px;
            padding: 20px 5px;
            display: flex;
            flex-wrap: wrap;
            span{
              position: relative;
              padding: 5px 15px;
              cursor: pointer;
              font-size: 14px;
              color: #333333;

              &:hover{
                color: #1A9DFF;
                cursor: pointer;
                transition: 0.3s;
              }

              &::after{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
                width: 1px;
                height: 10px;
                background: #BDBDBD;
              }
            }
          }
        }
      }

      .menu_box{
        ::v-deep{
          .el-menu--horizontal>.el-submenu .el-submenu__title,.el-menu--horizontal>.el-menu-item{
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #222222;
          }
          .el-menu.el-menu--horizontal{
            border: none;
          }
          .el-submenu{
            &:hover .el-submenu__title{
              color: #1A9DFF;
            }
          }
          .el-menu-item,.el-submenu__title{
            border: none !important;

            &:hover{
              color: #1A9DFF;
            }
          }
          .el-menu--horizontal>.el-menu-item.is-active,.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
            border: none;
            color: #1A9DFF;
          }
          .el-icon-arrow-down:before{
            content: "";
            background-image: url("~@/assets/images/index/xiala.svg");
            width: 9px;
            height: 9px;
            display: inline-block;
          }
          .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
            margin-left: 3px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1380px) {
  .toolbar_wrap .type_area{
    .toolbar_box,.back_order{
      position: fixed;
      right: 0;
    }
  }
}
@media screen and (max-width: 1660px) {
  .toolbar_wrap .type_area{
    .toolbar_box .xf_box{
      right: 66px;
      left: auto;

      .jt{
        right: -15px;
        left: auto;
        border-left: 8px solid #fff;
        border-right: 8px solid transparent;
      }
    }
  }
}
</style>
<style>
/* 菜单栏标签下拉框选中样式 */
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal .el-menu .el-menu-item.is-active, .el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title{
    color: #1A9DFF;
  }
</style>