<template>
  <div>
      <!-- 公共头 -->
      <Head></Head>

      <!-- 主要区域容器 -->
      <div class="main_box">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
      <!-- 主要区域容器END -->


      <!-- 公共尾 -->
      <Footer></Footer>
    </div>
</template>

<script>
import Head from './Head.vue'
import Footer from './Footer.vue'

export default {
  name: 'Home',
  components: {
    Head,
    Footer
  },
  data() {
    return {
      
    };
  },
  created() {
    
  },
  methods: {
    
  }
};
</script>
<style>
.main_box{
  min-width: 1312px;
  min-height: calc(100vh - 549px);
  padding: 163px 0 20px;
  background: #F4F4F4;
}
</style>